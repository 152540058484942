import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useConsent } from 'services/cookie-policy';
import { getSite } from 'concepts/site';
import { getUser } from 'concepts/user';
import { getIsFromEu } from 'concepts/i18n';
import { initializeSiteAndMts, getSiteInitializationStatus } from 'concepts/app';
import loadHelpScout from 'services/load-help-scout';
import loadCustomerIo from 'services/load-customer-io';
import { cioIdentify } from 'utils/customer-io';
import CookiePopup from 'components/CookiePopup';
import PageLoader from 'components/PageLoader';
import HelpScout from 'components/HelpScout';

const Site = ({
  children,
  initializeSiteAndMts,
  isInitializing,
  isFromEu,
  site,
  siteUrl,
  user,
}) => {
  const [consent] = useConsent();
  const [isHelpScoutAdded, setIsHelpScoutAdded] = useState(false);
  const hasCustomerSupportConsent = consent && consent.customer_support;
  const nonEuWithoutConsent = isFromEu === false && !consent;
  const canLoadHelpScout = hasCustomerSupportConsent || nonEuWithoutConsent;

  useEffect(() => {
    if (!site) {
      initializeSiteAndMts(siteUrl);
    }
  }, [initializeSiteAndMts, site, siteUrl]);

  useEffect(() => {
    if (!isHelpScoutAdded && canLoadHelpScout) {
      loadHelpScout();
      setIsHelpScoutAdded(true);
    }
  }, [isHelpScoutAdded, canLoadHelpScout]);

  useEffect(() => {
    if (user?.id && site?.uuid) {
      loadCustomerIo();
      cioIdentify(user, site);
    }
  }, [site, user]);

  if (!site || isInitializing) {
    return <PageLoader />;
  }

  return (
    <React.Fragment>
      {isHelpScoutAdded && !isEmpty(user) && canLoadHelpScout && <HelpScout user={user} />}
      {!consent && isFromEu && <CookiePopup />}

      {children}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isInitializing: getSiteInitializationStatus(state),
  isFromEu: getIsFromEu(state),
  site: getSite(state),
  user: getUser(state),
});

const mapDispatchToProps = { initializeSiteAndMts };

export default connect(mapStateToProps, mapDispatchToProps)(Site);
