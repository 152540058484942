import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import config from 'config';
import { ONBOARDING_STEPS } from 'constants/onboarding';
import { getConversationState } from 'services/conversation-updater';
import { createOnboardingContent } from 'services/api';
import { STEPS } from 'services/media-trackers/common';
import { answerQuestion, getAnswers, revertToQuestion } from 'concepts/feed-builder';
import { redirectToFlocklerManualContent } from 'concepts/redirect-flockler';
import { getSite, reloadSite } from 'concepts/site';
import ConversationMessageList from 'components/ConversationMessageList';
import AnimatedDots from 'components/AnimatedDots';
import { postHogTrackEvent } from 'utils/post-hog-sync';

const EMPTY_RESPONSE = { value: null, text: null };

class ConversationAboutSampleContent extends React.Component {
  state = { isCreatingFeed: false };

  createContent = () => {
    const { site, answerQuestion, reloadSite } = this.props;
    const { isCreatingFeed } = this.state;

    if (isCreatingFeed) return;

    this.setState(state => ({ ...state, isCreatingFeed: true }));

    createOnboardingContent(site.id)
      .then(reloadSite)
      .then(() => {
        this.setState(state => ({ ...state, isCreatingFeed: false }));
        answerQuestion({
          questionId: ONBOARDING_STEPS.CREATE_SAMPLE_CONTENT,
          nextId: ONBOARDING_STEPS.SAMPLE_CONTENT_CREATED,
          response: EMPTY_RESPONSE,
        });
      })
      .catch(() => {
        this.setState(state => ({ ...state, isCreatingFeed: false }));

        answerQuestion({
          questionId: ONBOARDING_STEPS.CREATE_SAMPLE_CONTENT,
          nextId: ONBOARDING_STEPS.SAMPLE_CONTENT_ERROR,
          response: EMPTY_RESPONSE,
        });
      });
  };

  getConversationTemplate = () => {
    const { redirectToFlocklerManualContent, revertToQuestion, answerQuestion, site, cookies } =
      this.props;
    const { isCreatingFeed } = this.state;

    return [
      {
        id: ONBOARDING_STEPS.CREATE_SAMPLE_CONTENT,
        nextId: ONBOARDING_STEPS.SAMPLE_CONTENT_CREATED,
        isUserReplyDisabled: true,
        disableAnimation: true,
        onLoad: () => {
          this.createContent();
        },
        messages:
          !isCreatingFeed || site.has_demo_articles
            ? []
            : [
                <span>
                  Creating feed
                  <AnimatedDots />
                </span>,
              ],
      },

      {
        id: ONBOARDING_STEPS.SAMPLE_CONTENT_ERROR,
        nextId: STEPS.CHOOSE_SERVICE,
        messages: [
          'Something went wrong while creating the sample Instagram Feed. 😢',
          'Do you want to try again or connect your own social media source?',
        ],
        actions: [
          {
            action: () => {
              revertToQuestion(ONBOARDING_STEPS.CREATE_SAMPLE_CONTENT);
              this.createContent();
            },
            id: 'retry',
            name: 'Retry',
          },
          {
            action: () => {
              revertToQuestion(ONBOARDING_STEPS.CHOOSE_ONBOARDING);
              answerQuestion({
                questionId: ONBOARDING_STEPS.CHOOSE_ONBOARDING,
                nextId: STEPS.CHOOSE_SERVICE,
                response: { value: 'create-feed', text: 'Connect to a social media source' },
              });
            },
            id: 'connect',
            name: 'Connect to a social media source',
          },
        ],
      },

      {
        id: ONBOARDING_STEPS.SAMPLE_CONTENT_CREATED,
        messages: ['I’ve created a sample Instagram Feed, let’s check it out! ✨'],
        actions: [
          {
            action: async () => {
              postHogTrackEvent('onboarding:feeds:take_a_look_button_click');
              cookies.set(`onboarding_${site.site_url}`, 'in-progress', {
                path: '/',
                domain: config.flocklerBaseDomain,
              });

              const sampleSections = site.sections.filter(section =>
                section.section_url.startsWith('sample-content')
              );
              const section = sampleSections.length
                ? sampleSections[sampleSections.length - 1]
                : site.sections[0];
              return redirectToFlocklerManualContent(section?.id);
            },
            id: 'start_onboarding',
            name: 'Take a look',
          },
        ],
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

const mapStateToProps = state => ({
  site: getSite(state),
  answers: getAnswers(state),
});

const mapDispatchToProps = {
  answerQuestion,
  revertToQuestion,
  redirectToFlocklerManualContent,
  reloadSite,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCookies
)(ConversationAboutSampleContent);
