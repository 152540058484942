// To create feed config
export const SERVICES = Object.freeze({
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram_graph_api',
  INSTAGRAM_BASIC: 'instagram_basic_display',
  TIKTOK: 'tiktok',
  TWITTER: 'twitter',
  TWITTER_V2: 'twitter_v2',
  PINTEREST: 'pinterest',
  RSS: 'rss',
  REVIEWS: 'reviews',
  YOUTUBE: 'youtube',
  LINKEDIN: 'linkedin',
  GOOGLE_REVIEW: 'google_review',
});

// To create conversation flow type
export const FEED_TYPES = Object.freeze({
  FACEBOOK: 'Facebook',
  LINKEDIN: 'LinkedIn',
  INSTAGRAM: 'Instagram',
  INSTAGRAM_BASIC: 'Instagram Basic Display',
  PINTEREST: 'Pinterest',
  RSS: 'RSS',
  REVIEWS: 'Reviews',
  TIKTOK: 'TikTok',
  TWITTER: 'Twitter',
  TWITTER_V2: 'Twitter',
  YOUTUBE: 'YouTube',
  GOOGLE_REVIEW: 'Google Reviews',
});

// Todo, we should refactor app to use only SERVICES instead of FEED_TYPES
// And for naming logic this constant
export const FEED_TYPE_NAMES = {
  [FEED_TYPES.FACEBOOK]: 'Facebook',
  [FEED_TYPES.LINKEDIN]: 'LinkedIn',
  [FEED_TYPES.INSTAGRAM]: 'Instagram',
  [FEED_TYPES.INSTAGRAM_BASIC]: 'Instagram',
  [FEED_TYPES.PINTEREST]: 'Pinterest',
  [FEED_TYPES.TWITTER]: 'Twitter',
  [FEED_TYPES.TIKTOK]: 'TikTok',
  [FEED_TYPES.YOUTUBE]: 'YouTube',
  [FEED_TYPES.RSS]: 'RSS',
  [FEED_TYPES.REVIEWS]: 'Reviews',
  [FEED_TYPES.GOOGLE_REVIEW]: 'Google Reviews',
};

// map services to feed_types
export const SERVICE_TO_FEED_TYPE = Object.freeze({
  [SERVICES.FACEBOOK]: FEED_TYPES.FACEBOOK,
  [SERVICES.LINKEDIN]: FEED_TYPES.LINKEDIN,
  [SERVICES.INSTAGRAM]: FEED_TYPES.INSTAGRAM,
  [SERVICES.INSTAGRAM_BASIC]: FEED_TYPES.INSTAGRAM_BASIC,
  [SERVICES.PINTEREST]: FEED_TYPES.PINTEREST,
  [SERVICES.TWITTER]: FEED_TYPES.TWITTER,
  [SERVICES.TWITTER_V2]: FEED_TYPES.TWITTER_V2,
  [SERVICES.TIKTOK]: FEED_TYPES.TIKTOK,
  [SERVICES.YOUTUBE]: FEED_TYPES.YOUTUBE,
  [SERVICES.RSS]: FEED_TYPES.RSS,
  [SERVICES.REVIEWS]: FEED_TYPES.REVIEWS,
  [SERVICES.GOOGLE_REVIEW]: FEED_TYPES.GOOGLE_REVIEW,
});

export const FEED_TYPE_TO_SERVICE = Object.freeze({
  [FEED_TYPES.FACEBOOK]: SERVICES.FACEBOOK,
  [FEED_TYPES.LINKEDIN]: SERVICES.LINKEDIN,
  [FEED_TYPES.INSTAGRAM]: SERVICES.INSTAGRAM,
  [FEED_TYPES.INSTAGRAM_BASIC]: SERVICES.INSTAGRAM_BASIC,
  [FEED_TYPES.PINTEREST]: SERVICES.PINTEREST,
  [FEED_TYPES.TWITTER]: SERVICES.TWITTER,
  [FEED_TYPES.TWITTER_V2]: SERVICES.TWITTER_V2,
  [FEED_TYPES.TIKTOK]: SERVICES.TIKTOK,
  [FEED_TYPES.YOUTUBE]: SERVICES.YOUTUBE,
  [FEED_TYPES.RSS]: SERVICES.RSS,
  [FEED_TYPES.REVIEWS]: SERVICES.REVIEWS,
  [FEED_TYPES.GOOGLE_REVIEW]: SERVICES.GOOGLE_REVIEW,
});
