// # App concept
import { createSelector } from 'reselect';

import { getUser, fetchUser } from 'concepts/user';
import { fetchInternationalization } from 'concepts/i18n';

import { fetchSite, getSiteMaxFeeds } from 'concepts/site';
import { fetchMediaTrackers, getEnabledMediaTrackerCount } from 'concepts/media-tracker';
import { fetchSocialMediaAccounts } from 'concepts/social-media-account';
import { fetchStatus } from 'services/api';
import { fetchFeatureFlags } from 'concepts/feature-flags';
import { evaluateOnboardingVisibility } from 'concepts/onboarding';

// Action types
const ACTIONS = Object.freeze({
  INITIALIZE_SITE: 'APP/INITIALIZE_SITE',
  INITIALIZE_SITE_SUCCESS: 'APP/INITIALIZE_SITE_SUCCESS',
  INITIALIZE_SITE_FAIL: 'APP/INITIALIZE_SITE_FAIL',
});

// Selectors
export const getSiteInitializationStatus = state => state.app.isInitializingSite;
export const getMediaTrackersLimitReached = createSelector(
  getSiteMaxFeeds,
  getEnabledMediaTrackerCount,
  (maxFeedCount, enabledMediaTrackerCount) => maxFeedCount <= enabledMediaTrackerCount
);

// Action creators
export const initializeApp = () => (dispatch, getState) => {
  // fetch status to get csrf-token which is needed for media tracker creation
  // @todo should be updated?
  fetchStatus();

  // initialize user
  const user = getUser(getState());
  if (!user) {
    dispatch(fetchUser());
    dispatch(fetchInternationalization());
  }
};

export const initializeSiteAndMts = siteUrl => dispatch => {
  dispatch({ type: ACTIONS.INITIALIZE_SITE });

  return dispatch(fetchSite(siteUrl))
    .then(() =>
      Promise.all([
        dispatch(fetchMediaTrackers()),
        dispatch(fetchSocialMediaAccounts()),
        dispatch(fetchFeatureFlags()),
      ])
    )
    .then(() => dispatch(evaluateOnboardingVisibility()))
    .then(() => dispatch({ type: ACTIONS.INITIALIZE_SITE_SUCCESS }))
    .catch(() => dispatch({ type: ACTIONS.INITIALIZE_SITE_FAIL }));
};

const initialState = Object.freeze({
  isInitializingSite: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.INITIALIZE_SITE: {
      return { ...state, isInitializingSite: true };
    }

    case ACTIONS.INITIALIZE_SITE_FAIL:
    case ACTIONS.INITIALIZE_SITE_SUCCESS: {
      return { ...state, isInitializingSite: false };
    }

    default:
      return state;
  }
}
