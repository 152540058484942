import Analytics from 'analytics';
import postHog from '@metro-fs/analytics-plugin-posthog';
import appConfig from 'config';

const initPostHogAnalytics = () => {
  return Analytics({
    app: 'flockler',
    plugins: [
      postHog({
        token: appConfig.postHogApiKey,
        enabled: true,
        options: {
          api_host: appConfig.postHogHost,
          debug: process.env.REACT_APP_ENV !== 'production',
          persistence: 'memory',
          disable_session_recording: true,
        },
      }),
    ],
  });
};

let _postHogAnalytics = null;
const getPostHogAnalytics = () => {
  if (!_postHogAnalytics) {
    _postHogAnalytics = initPostHogAnalytics();
  }
  return _postHogAnalytics;
};

/**
 * Identifies a user and tracks group identification with PostHog analytics.
 *
 * @param {string} userId - The unique identifier for the user.
 * @param {string} siteId - The unique identifier for the site.
 * @returns {Promise} A promise that resolves when the identification and tracking are complete.
 */
export const postHogIdentifyUser = async (userId, siteId) => {
  const tracker = getPostHogAnalytics();
  await tracker.identify(`${userId}`);

  const groupProperties = { $group_type: 'site', $group_key: `${siteId}` };
  await tracker.track('$groupidentify', groupProperties);
};

/**
 * Tracks an event using PostHog analytics.
 *
 * @param {string} event - The name of the event to track.
 * @param {Object} [payload] - The payload data to send with the event.
 * @param {Object} [options] - Optional additional options for tracking the event.
 * @returns {Promise} - A promise that resolves when the event has been tracked.
 */
export const postHogTrackEvent = (event, payload, options) => {
  const tracker = getPostHogAnalytics();
  return tracker.track(event, payload, options);
};
