export const ACTIONS = Object.freeze({
  ADD_CREATED_FEEDS_COUNT: 'FEED_BUILDER/ADD_CREATED_FEEDS_COUNT',
  ANSWER_QUESTION: 'FEED_BUILDER/ANSWER_QUESTION',
  EMPTY_ANSWERS: 'FEED_BUILDER/EMPTY_ANSWERS',
  REVERT_TO_QUESTION: 'FEED_BUILDER/REVERT_TO_QUESTION',
  SET_ANSWERS: 'FEED_BUILDER/SET_ANSWERS',
  SET_CHANNEL_CONVERSATION_STATUS: 'FEED_BUILDER/SET_CHANNEL_CONVERSATION_STATUS',
  SET_CREATED_CONVERSATION_STATUS: 'FEED_BUILDER/SET_CREATED_CONVERSATION_STATUS',
  SET_MT_CREATION_STATUS: 'FEED_BUILDER/SET_MT_CREATION_STATUS',
  SET_ACCOUNT_ID: 'FEED_BUILDER/SET_ACCOUNT_ID',
  SET_FEED_TYPE: 'FEED_BUILDER/SET_FEED_TYPE',
  SET_REFRESH_CONVERSATION: 'FEED_BUILDER/SET_REFRESH_CONVERSATION',
  CLEAR_STATE: 'FEED_BUILDER/CLEAR_STATE',
});
