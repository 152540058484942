const cioIdentify = (user, site) => {
  window._cio.identify({
    id: `${site.uuid}-${user.id}`,
  });
};

const cioTrackEvent = (event, data) => {
  window._cio.track(event, data);
};

export { cioIdentify, cioTrackEvent };
