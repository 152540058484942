import { createSelector } from 'reselect';
import { getUserHasCompletedOnboarding, getUserHasCreatedFirstFeed } from './user';
import { getOnboardingFeatureFlag } from './feature-flags';
import { getSiteSubscription } from './site';
import { ONBOARDING_STEPS } from 'constants/onboarding';
import { ACTIONS as FEED_BUILDER_ACTIONS } from './feed-builder/actions';

// Action Types

export const ACTIONS = Object.freeze({
  SET_ONBOARDING_VISIBLE: 'ONBOARDING/SET_ONBOARDING_VISIBLE',
});

// Action creators

export const setOnboardingVisibility = visible => dispatch => {
  dispatch({ type: ACTIONS.SET_ONBOARDING_VISIBLE, payload: visible });
};

export const evaluateOnboardingVisibility = () => (dispatch, getState) => {
  const showOnboarding = getShowOnboarding(getState());

  dispatch(setOnboardingVisibility(showOnboarding));
};

export const showOnboarding = () => dispatch => {
  dispatch({ type: FEED_BUILDER_ACTIONS.CLEAR_STATE });

  dispatch({ type: FEED_BUILDER_ACTIONS.SET_REFRESH_CONVERSATION, payload: true });
  dispatch(setOnboardingVisibility(true));

  dispatch({
    type: FEED_BUILDER_ACTIONS.ANSWER_QUESTION,
    payload: {
      questionId: ONBOARDING_STEPS.CHOOSE_ONBOARDING,
      nextId: ONBOARDING_STEPS.CREATE_SAMPLE_CONTENT,
      response: { value: 'create-sample', text: 'Try out sample content' },
    },
  });

  setTimeout(() => {
    dispatch({ type: FEED_BUILDER_ACTIONS.SET_REFRESH_CONVERSATION, payload: false });
  }, 800);
};

// Selectors

export const getOnboardingVisible = state => state.onboarding.visible;

export const getShowOnboarding = createSelector(
  [getOnboardingFeatureFlag, getUserHasCreatedFirstFeed, getUserHasCompletedOnboarding],
  (onboardingFeatureFlag, userHasCreatedFirstFeed, hasCompletedOnboarding) => {
    return onboardingFeatureFlag && !hasCompletedOnboarding && !userHasCreatedFirstFeed;
  }
);

export const getIsOnboardingAvailable = createSelector(
  [getSiteSubscription, getOnboardingFeatureFlag],
  (siteSubscription, onboardingFeatureFlagEnabled) => {
    return siteSubscription?.state === 'trial' && onboardingFeatureFlagEnabled;
  }
);

// Reducer

export const initialState = Object.freeze({
  visible: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_ONBOARDING_VISIBLE: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
