import React from 'react';
import { connect } from 'react-redux';

import AppContainer from 'components/AppContainer';
import { getFlocklerSiteUrl } from 'concepts/redirect-flockler';
import { getSelectedFeedTypeName, getSessionCreatedFeedsCount } from 'concepts/feed-builder';
import { getUserHasCompletedOnboarding, getUserHasCreatedFirstFeed } from 'concepts/user';
import { getIsOnboardingAvailable, showOnboarding } from 'concepts/onboarding';

class FeedsAppContainer extends React.Component {
  render() {
    const {
      children,
      flocklerSiteUrl,
      hasCreatedFeedBefore,
      selectedFeedTypeName,
      sessionCreatedFeedCount,
      isOnboardingAvailable,
      hasCompletedOnboarding,
      showOnboarding,
    } = this.props;

    const hasCreatedFeedDuringSession = sessionCreatedFeedCount > 0;
    const hasCreatedFeed = hasCreatedFeedBefore || hasCreatedFeedDuringSession;

    return (
      <AppContainer
        title={<h1>Create an Automated {selectedFeedTypeName ? selectedFeedTypeName : ''} Feed</h1>}
        continueToAppUrl={hasCreatedFeed || hasCompletedOnboarding ? flocklerSiteUrl : null}
        showOnboarding={isOnboardingAvailable ? showOnboarding : null}
      >
        {children}
      </AppContainer>
    );
  }
}

const mapStateToProps = state => ({
  flocklerSiteUrl: getFlocklerSiteUrl(state),
  hasCreatedFeedBefore: getUserHasCreatedFirstFeed(state),
  selectedFeedTypeName: getSelectedFeedTypeName(state),
  sessionCreatedFeedCount: getSessionCreatedFeedsCount(state),
  isOnboardingAvailable: getIsOnboardingAvailable(state),
  hasCompletedOnboarding: getUserHasCompletedOnboarding(state),
});

const mapDispatchToProps = { showOnboarding };

export default connect(mapStateToProps, mapDispatchToProps)(FeedsAppContainer);
