import { useEffect, useRef } from 'react';

const UserMessageAutoAction = ({ action }) => {
  const hasRun = useRef(false);

  useEffect(() => {
    if (typeof action === 'function' && hasRun.current === false) {
      hasRun.current = true;
      action();
    }
  }, [action]);

  return null;
};

export default UserMessageAutoAction;
