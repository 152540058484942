import React from 'react';

import Header from 'components/Header';
import styles from './AppContainer.module.scss';

const AppContainer = ({ continueToAppUrl, showOnboarding, children, title, ...rest }) => (
  <div className={styles.appContainer} {...rest}>
    <Header continueToAppUrl={continueToAppUrl} showOnboarding={showOnboarding}>
      {title}
    </Header>

    <div className={styles.content}>{children}</div>
  </div>
);

export default AppContainer;
