// # Feed builder concept
import { createSelector } from 'reselect';
import maxBy from 'lodash/maxBy';
import get from 'lodash/get';
import { buildFeedConfig } from 'services/feed-config-builder';
import * as site from 'concepts/site';
import * as app from 'concepts/app';
import * as mediaTracker from 'concepts/media-tracker';
import { trackEvent } from 'services/analytics';
import { cleanEventValues } from 'utils/analytics-events';
import {
  getSocialMediaAccounts,
  getServiceSocialMediaAccounts,
  fetchSocialMediaAccounts,
} from 'concepts/social-media-account';
import { FEED_TYPES, FEED_TYPE_NAMES, FEED_TYPE_TO_SERVICE } from 'constants/Services';
import { STEPS } from 'services/media-trackers/common';
import { QUESTION_IDS as REVIEW_QUESTION } from 'services/media-trackers/reviews';

import { connectAccount } from 'services/connect-account';
import { feedConfigToDto, previewConfigToDto } from 'services/media-tracker-dto';
import { updatePageTitle } from 'utils/page-title';
import { cioTrackEvent } from 'utils/customer-io';
import { postHogTrackEvent } from 'utils/post-hog-sync';

import { ACTIONS } from './actions';

// Selectors
export const getAccountId = state => state.feedBuilder.selectedAccountId;
export const getSessionCreatedFeedsCount = state => state.feedBuilder.createdFeedsCount;
export const getConversationFeedType = state => state.feedBuilder.selectedFeedType;
export const getAnswers = state => state.feedBuilder.answers;
export const getChannelConversationStatus = state => state.feedBuilder.isChannelConversationDone;
export const getFeedCreationConversationStatus = state =>
  state.feedBuilder.isFeedCreationConversationDone;
export const getConversationRefreshStatus = state => state.feedBuilder.isRefreshingConversation;
export const getMediaTrackerCreationStatus = state => state.feedBuilder.isCreatingMediaTracker;

export const getFeedSentStatus = createSelector(
  getMediaTrackerCreationStatus,
  getFeedCreationConversationStatus,
  (isCreating, isCreatingDone) => isCreating || isCreatingDone
);

export const getSelectedFeedTypeName = createSelector(getConversationFeedType, feedType =>
  get(FEED_TYPE_NAMES, feedType)
);

export const getRouteParamSectionId = site.getRouteParamSectionId;
export const getSiteSections = site.getSiteSections;
export const getSiteMaxMediaTrackers = site.getSiteMaxFeeds;
export const getMediaTrackersLimitReached = app.getMediaTrackersLimitReached;
export const getSectionId = site.getSectionId;
export const getSiteUrl = site.getSiteUrl;
export const getHistoryPreviewLoadingState = mediaTracker.getHistoryPreviewLoadingState;
export const getHistoryPreviewResult = mediaTracker.getHistoryPreviewResult;
export const getPreviewItemsCount = mediaTracker.getPreviewItemsCount;

export const getCurrentFeedCount = mediaTracker.getEnabledMediaTrackerCount;

// Creates selector with reselect createSelector for feed config
// With createSelector result is memoized and will be re-calculated only if one of it's parameters updates!
// Selector takes functions as parameters which all will get state as parameter
export const getFeedConfig = createSelector(getAnswers, buildFeedConfig);

export const getQuestionAnswer = questionId =>
  createSelector(getAnswers, answers => {
    const question = answers.find(answer => answer.questionId === questionId) || {};
    return question.response;
  });

export const getQuestionAnswerValue = questionId =>
  createSelector(getQuestionAnswer(questionId), (answer = {}) => (answer ? answer.value : null));

export const getServiceAccounts = getServiceSocialMediaAccounts;

export const getSelectedAccount = createSelector(
  getAccountId,
  getSocialMediaAccounts,
  (id, accounts) => accounts.find(account => account.id === id)
);

// Action Creators

export const loadConversationState = state => dispatch => {
  if (state === 'reviewFormEditCancel') {
    // Set review first user experience state
    dispatch(setFeedType(FEED_TYPES.REVIEWS));

    dispatch({
      type: ACTIONS.SET_ANSWERS,
      payload: [
        {
          questionId: STEPS.CHOOSE_SERVICE,
          response: { value: FEED_TYPES.REVIEWS, text: 'Reviews' },
        },
        {
          questionId: REVIEW_QUESTION.INTRODUCTION,
          nextId: REVIEW_QUESTION.CONFIRM_DPA,
          response: { value: 'yes', text: 'Yes, I’d like to start!' },
        },
        {
          questionId: REVIEW_QUESTION.CONFIRM_DPA,
          nextId: REVIEW_QUESTION.CREATE_DEMO_REVIEWS,
          response: { value: 'yes', text: 'Yes, I accept' },
        },
      ],
    });

    // Wait a bit until last answer to make sure that focus is moved to that…
    setTimeout(() => {
      dispatch(
        answerQuestion({
          questionId: REVIEW_QUESTION.CREATE_DEMO_REVIEWS,
          nextId: REVIEW_QUESTION.CREATE_REVIEW_FORM,
          response: { value: 'great', text: 'Great, let’s create a form!' },
        })
      );
    }, 1000);

    return;
  }

  if (state === 'reviewFormEditSuccess') {
    // Set review first user experience state
    dispatch(setFeedType(FEED_TYPES.REVIEWS));

    dispatch({
      type: ACTIONS.SET_ANSWERS,
      payload: [
        {
          questionId: STEPS.CHOOSE_SERVICE,
          response: { value: FEED_TYPES.REVIEWS, text: 'Reviews' },
        },
        {
          questionId: REVIEW_QUESTION.INTRODUCTION,
          nextId: REVIEW_QUESTION.CONFIRM_DPA,
          response: { value: 'yes', text: 'Yes, I’d like to start!' },
        },
        {
          questionId: REVIEW_QUESTION.CONFIRM_DPA,
          nextId: REVIEW_QUESTION.CREATE_DEMO_REVIEWS,
          response: { value: 'yes', text: 'Yes, I accept' },
        },
        {
          questionId: REVIEW_QUESTION.CREATE_DEMO_REVIEWS,
          nextId: REVIEW_QUESTION.CREATE_REVIEW_FORM,
          response: { value: 'great', text: 'Great, let’s create a form!' },
        },
      ],
    });

    // Wait a bit until last answer to make sure that focus is moved to that…
    setTimeout(() => {
      dispatch(
        answerQuestion({
          response: { value: 'edit', text: 'I’d like to edit the form!' },
          questionId: REVIEW_QUESTION.CREATE_REVIEW_FORM,
          nextId: REVIEW_QUESTION.EMBED_REVIEW_FORM,
        })
      );
    }, 1500);

    return;
  }
};

export const trackAnswerEvent = ({ questionId, response = {}, disableValueTracking }) => {
  const eventOpts = {
    category: 'feed-answer',
    action: questionId,
    label: disableValueTracking ? '' : cleanEventValues(response.value),
  };

  trackEvent(eventOpts);
};

export const trackQuestionRevert = questionId => {
  const eventOpts = {
    category: 'feed-action',
    action: 'revert to question',
    label: questionId,
  };

  trackEvent(eventOpts);
};

export const answerQuestion = answer => dispatch => {
  trackAnswerEvent(answer);

  return dispatch({ type: ACTIONS.ANSWER_QUESTION, payload: answer });
};

export const revertToQuestion = questionId => dispatch => {
  trackQuestionRevert(questionId);

  return dispatch({
    type: ACTIONS.REVERT_TO_QUESTION,
    payload: questionId,
  });
};

export const setChannelConversationStatus = status => ({
  type: ACTIONS.SET_CHANNEL_CONVERSATION_STATUS,
  payload: status,
});

export const setCreatedConversationStatus = status => ({
  type: ACTIONS.SET_CREATED_CONVERSATION_STATUS,
  payload: status,
});

export const setAccountId = accountId => ({ type: ACTIONS.SET_ACCOUNT_ID, payload: accountId });
export const setFeedType = feedType => dispatch => {
  notifyPostHogFeedTypeSet(feedType);
  notifyCustomerioFeedTypeSet(feedType);
  updatePageTitle({ feedType });
  return dispatch({ type: ACTIONS.SET_FEED_TYPE, payload: feedType });
};

export const createNewFeed = () => dispatch => {
  updatePageTitle();

  // Clear state
  dispatch({ type: ACTIONS.CLEAR_STATE });

  dispatch({ type: ACTIONS.ADD_CREATED_FEEDS_COUNT });

  // Toggle refresh flag, which will reload conversation message animations from start
  dispatch({ type: ACTIONS.SET_REFRESH_CONVERSATION, payload: true });
  setTimeout(() => dispatch({ type: ACTIONS.SET_REFRESH_CONVERSATION, payload: false }), 400);
};

export const connectServiceAccount = service => (dispatch, getState) => {
  return connectAccount(service)
    .then(() => dispatch(fetchSocialMediaAccounts()))
    .then(() => {
      const socialMediaAccounts = getSocialMediaAccounts(getState());

      // return latest media tracker account
      return maxBy(socialMediaAccounts, 'connected_at');
    });
};

export const createHistoryPreview = () => (dispatch, getState) => {
  const feedConfig = getFeedConfig(getState());
  const previewMediaTrackerDTO = previewConfigToDto(feedConfig);

  return dispatch(mediaTracker.createHistoryPreview(previewMediaTrackerDTO));
};

const waitMediaTrackerHistoryImport = (mtId, service) => dispatch => {
  const MAX_ATTEMPTS = service === 'rss' ? 30 : 11;
  const ATTEMPT_INTERVAL_MS = 1000;

  let attemptCount = 0;
  let waitChecker;

  // This will be rejected
  // - for normal feeds until "currently_fetching_history" and "history_post_processors_running" are explicitly false
  // - for abstract feeds until "last_run_completed_at" is defined
  const trialPromise = () =>
    dispatch(mediaTracker.fetchMediaTracker(mtId)).then(({ data }) => {
      const isReady = data?.abstract_tracker
        ? !!data?.media_tracker_state?.last_run_completed_at
        : data?.currently_fetching_history === false &&
          data?.history_post_processors_running === false;

      if (isReady) {
        // Now history is ready!
        return;
      }

      return Promise.reject();
    });

  // Returns promise which starts setInterval
  // to check history fetch status.
  // Promise will be resolved when history fetch is done OR max attempts have reached.
  // This promise will never be rejected.
  return new Promise(function (resolve) {
    waitChecker = setInterval(() => {
      attemptCount = attemptCount + 1;
      const isMaxAttemptCountReached = attemptCount > MAX_ATTEMPTS;

      if (isMaxAttemptCountReached) {
        clearInterval(waitChecker);
        resolve();
        return;
      }

      trialPromise()
        .then(() => {
          clearInterval(waitChecker);
          resolve();
        })
        .catch(() => {
          // keep on trying...
        });
    }, ATTEMPT_INTERVAL_MS);
  });
};

const setMediaTrackerCreationStatus = status => ({
  type: ACTIONS.SET_MT_CREATION_STATUS,
  payload: status,
});

const finishMediaTrackerCreation = () => dispatch => {
  dispatch(setCreatedConversationStatus(true));
  dispatch(setMediaTrackerCreationStatus(false));
};

export const createMediaTracker = () => (dispatch, getState) => {
  // Start loading
  dispatch(setMediaTrackerCreationStatus(true));

  const state = getState();
  const feedConfig = getFeedConfig(state);
  const previewKey = mediaTracker.getPreviewKey(state);
  const sectionId = site.getSectionId(state);

  // If section id is not found in feedConfig, use one from site
  const hasSectionIdAlready = feedConfig.section_ids && feedConfig.section_ids.length > 0;
  const sectionIds = hasSectionIdAlready ? feedConfig.section_ids : [sectionId];

  // Extend feed config data with values from store
  const feedConfigWithStoreData = Object.assign({}, feedConfig, {
    section_ids: sectionIds,
    preview_key: previewKey,
  });

  const mediaTrackerDTO = feedConfigToDto(feedConfigWithStoreData);

  return dispatch(mediaTracker.createMediaTracker(mediaTrackerDTO))
    .then(mtResponse => {
      if (!mtResponse.id) {
        dispatch(finishMediaTrackerCreation());
        return Promise.reject();
      }

      return mtResponse;
    })
    .then(({ id, service }) => dispatch(waitMediaTrackerHistoryImport(id, service)))
    .then(() => dispatch(finishMediaTrackerCreation()))
    .catch(error => {
      dispatch(finishMediaTrackerCreation());

      let errorPayload = error.response || error.request || error.message;
      return Promise.reject(errorPayload);
    });
};

const notifyCustomerioFeedTypeSet = feedType => {
  const sourceName = FEED_TYPE_TO_SERVICE[feedType];

  if (sourceName) {
    cioTrackEvent(`source_clicked_${sourceName}`, { sourceName });
  }
};

const notifyPostHogFeedTypeSet = feedType => {
  if (feedType) {
    postHogTrackEvent('source_clicked', { category: feedType });
  }
};

// Reducer
export const initialState = Object.freeze({
  answers: [],
  createdFeedsCount: 0,
  isChannelConversationDone: false,
  isFeedCreationConversationDone: false,
  selectedFeedType: null,
  selectedAccountId: null,
  isRefreshingConversation: false,
  isCreatingMediaTracker: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ADD_CREATED_FEEDS_COUNT: {
      return {
        ...state,
        createdFeedsCount: state.createdFeedsCount + 1,
      };
    }

    case ACTIONS.ANSWER_QUESTION: {
      const answerIndex = state.answers.findIndex(
        answer => answer.questionId === action.payload.questionId
      );
      const isAnswerFound = answerIndex >= 0;

      // update if found
      // add if not found
      const answers = isAnswerFound
        ? state.answers
            .slice(0, answerIndex)
            .concat([action.payload])
            .concat(state.answers.slice(answerIndex + 1))
        : [...state.answers, action.payload];

      return {
        ...state,
        answers,
      };
    }

    case ACTIONS.EMPTY_ANSWERS: {
      return {
        ...state,
        answers: [],
      };
    }

    case ACTIONS.SET_ANSWERS: {
      return {
        ...state,
        answers: [...state.answers, ...action.payload],
      };
    }

    case ACTIONS.REVERT_TO_QUESTION: {
      const revertedQuestionIndex = state.answers.findIndex(
        answer => answer.questionId === action.payload
      );

      if (revertedQuestionIndex < 0) {
        return state;
      }

      const nextAnswers = state.answers.slice(0, revertedQuestionIndex);

      return {
        ...state,
        answers: [...nextAnswers],
      };
    }

    case ACTIONS.SET_CHANNEL_CONVERSATION_STATUS: {
      return {
        ...state,
        isChannelConversationDone: action.payload,
      };
    }

    case ACTIONS.SET_CREATED_CONVERSATION_STATUS: {
      return {
        ...state,
        isFeedCreationConversationDone: action.payload,
      };
    }

    case ACTIONS.SET_ACCOUNT_ID: {
      return {
        ...state,
        selectedAccountId: action.payload,
      };
    }

    case ACTIONS.SET_FEED_TYPE: {
      return {
        ...state,
        selectedFeedType: action.payload,
      };
    }

    case ACTIONS.SET_MT_CREATION_STATUS: {
      return {
        ...state,
        isCreatingMediaTracker: action.payload,
      };
    }

    case ACTIONS.SET_REFRESH_CONVERSATION: {
      return {
        ...state,
        isRefreshingConversation: action.payload,
      };
    }

    case ACTIONS.CLEAR_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
