// # Feature Flags concept
import * as api from 'services/api';
import { getSiteId } from 'concepts/site';
import { createSelector } from 'reselect';

// # Action types
const FETCH_FEATURE_FLAGS_SUCCESS = 'featureFlags/FETCH_FEATURE_FLAGS_SUCCESS';

// # Selectors
export const getFeatureFlags = state => state.featureFlags.featureFlags;
export const getOnboardingFeatureFlag = createSelector(
  getFeatureFlags,
  featureFlags => featureFlags['new_onboarding'] ?? false
);

// # Actions
export const fetchFeatureFlags = () => (dispatch, getState) => {
  const currentSiteId = getSiteId(getState());
  return api
    .fetchFeatureFlags(currentSiteId)
    .then(response => {
      dispatch({ type: FETCH_FEATURE_FLAGS_SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(() => console.log('Loading feature flags failed'));
};

export const initialState = {
  featureFlags: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_SUCCESS: {
      return {
        ...state,
        featureFlags: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
