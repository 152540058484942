import PropTypes from 'prop-types';
import WebComponent from 'utils/web-component';
import { useEffect, useState, useMemo } from 'react';
import throttle from 'lodash/throttle';

import { helpScoutMessage } from 'utils/help-scout';
import styles from './Header.module.scss';
import { postHogTrackEvent } from 'utils/post-hog-sync';

const Header = ({ continueToAppUrl, showOnboarding, children, ...rest }) => {
  const [narrowScreen, setNarrowScreen] = useState(false);

  const isNarrowScreen = () => {
    return typeof window !== 'undefined' ? window.innerWidth < 1024 : false;
  };

  useEffect(() => {
    const handleResize = () => {
      setNarrowScreen(isNarrowScreen());
    };

    setNarrowScreen(isNarrowScreen());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const throttleOnboarding = useMemo(
    () =>
      throttle(
        () => {
          if (showOnboarding) {
            postHogTrackEvent('onboarding:feeds:try_sample_header_button_click');
            showOnboarding();
          }
        },
        5000,
        { trailing: false }
      ),
    [showOnboarding]
  );

  return (
    <div className={styles.header} {...rest}>
      <div className={styles.headerContent}>
        <div className={styles.actionLinkHelpWCContainer}>
          <WebComponent
            tag="fl-button"
            variant="tetriary"
            class={`transparent ${narrowScreen ? 'tight' : ''}`}
            size={narrowScreen ? 'small' : 'medium'}
            onClick={() => helpScoutMessage({ subject: `Help with automated feed.` })}
            title="Help"
            aria-label="Help"
          >
            <span>Help</span>
          </WebComponent>
        </div>

        <div className={styles.headerTitle}>{children}</div>

        <div className={styles.actionLinks}>
          {showOnboarding && (
            <div>
              <WebComponent
                tag="fl-button"
                onClick={throttleOnboarding}
                variant={!continueToAppUrl ? 'secondary' : undefined}
                class={narrowScreen ? 'tight nowrap' : 'nowrap'}
                size={narrowScreen || continueToAppUrl ? 'small' : 'medium'}
                title="Try sample content"
                aria-label="Try sample content"
                responsive="true"
              >
                <span>{narrowScreen ? 'Sample' : 'Try sample content'} &rarr;</span>
              </WebComponent>
            </div>
          )}

          {continueToAppUrl && (
            <div>
              <WebComponent
                tag="fl-button"
                href={continueToAppUrl}
                variant="secondary"
                class={narrowScreen ? 'tight nowrap' : 'nowrap'}
                size={narrowScreen ? 'small' : 'medium'}
                title="Continue to App"
                aria-label="Continue to App"
                responsive="true"
              >
                <span>{narrowScreen ? '' : 'Continue to'} App &rarr;</span>
              </WebComponent>
            </div>
          )}

          {!continueToAppUrl && !showOnboarding && (
            <div>
              <div className={styles.actionLinkPlaceholder}></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  showOnboarding: null,
  continueToAppUrl: null,
  children: null,
};

Header.propTypes = {
  showOnboarding: PropTypes.func,
  continueToAppUrl: PropTypes.string,
  clildren: PropTypes.node,
};

export default Header;
